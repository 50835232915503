import React from 'react'
import FestifHelmet from '../../components/Helmet/FestifHelmet'
import Wolflayout from '../../components/wolflayout'
import InfosHeader from '../../components/Infos/InfosHeader'
import InfosNavigation from '../../components/Infos/InfosNavigation'
import ScenesDistanceFromParvis from '../../components/Infos/ScenesDistanceFromParvis'
import BigButton from '../../components/Ui/BigButton'

const Transports = () => {
  const metaTitle = 'Transport | Le Festif! de Baie-Saint-Paul'
  const metaDescription = 'Groupes de covoiturage, forfaits avec le Train de Charlevoix, location de vélos, navette vers le Quai Bell : le Festif! est votre allié en transport!'
  const metaKeywords = 'festival, musique, événement, spectacles, québec, charlevoix, transport, se rendre, covoiturage, train'

  return (
    <Wolflayout mobileTitle={'Infos'}>
      <FestifHelmet title={metaTitle} description={metaDescription} keywords={metaKeywords} />
      <InfosNavigation />
      <InfosHeader
        title="Transport"
      />
      <div className="container content pb-12">
        <h2>En direction de Baie-Saint-Paul</h2>
        <p>Dans l’objectif de réduire l’empreinte carbone de l’événement, Le Festif! encourage ses festivalières et festivaliers à opter pour le covoiturage. Rejoins le groupe <a href="https://www.facebook.com/groups/267497745542406/">Facebook</a> pour trouver ton lift.</p>
        <br/>
        <p>Grâce à Desjardins et à Mobilité Charlevoix, tu pourras embarquer dans la navette gratuite de La Malbaie jusqu’à Baie-Saint-Paul et de Baie-Saint-Paul jusqu’à La Malbaie. Attention, tu dois obligatoirement <a href="https://forms.gle/r4Zs3pGjmSZYkaUT7" target='_blank' rel="noreferrer">réserver</a> ta place.</p>

        <h2>À Baie-Saint-Paul</h2>
        <p>Une fois que tu seras à Baie-St-Paul, Le Festif! t’encourage à limiter autant que possible l’utilisation de ta voiture, tant par souci écologique que pour éviter de générer d’importants bouchons de circulation dans les rues du village. Ceci étant dit, les sites de spectacles étant tous très près les uns des autres, la majorité des déplacements peuvent aisément être effectués à pied. Pour les rares trajets nécessitant une marche de plus d’une dizaine de minutes, deux options sont offertes :</p>

        <h3>Le vélo</h3>
        <p>Grâce aux copains et aux copines de Baie-Cycle, tu pourras louer une bicyclette pour te déplacer librement dans les rues de Baie-St-Paul. <a href="http://www.baiecycle.org/">Baie-Cycle</a> aura quatre stations à ta disposition.</p>

        <h3>Navettes gratuites vers les sites</h3>

        <a href='/scenes/le-pit-a-sable' className='!no-underline hover:!underline'><h4>Pit à sable Hydro-Québec en collaboration avec Solotech</h4></a>
        <p>Les navettes sont à privilégier pour se rendre au Pit à Sable Hydro-Québec en collaboration avec Solotech.</p>
        <br/>
        <p>L’embarquement dans les navettes se fera au 22 Cherde L’Hôpital (stationnement de l’ancien Hôpital de Baie-St-Paul). L’accès à cette rue (aussi appelée Allée des Petites Franciscaines de Marie) se fait via la rue Racine ou R. Alfred-Morin, derrière l’école Sir-Rodolphe Forget. L’endroit se trouve facilement via Google map. Le stationnement pour la navette se trouve à proximité au P2 / P3 / P5.</p>
        <br/>
        <p>Vous devrez présenter votre billet électronique ou votre Passe La Totale afin de pouvoir entrer dans les navettes et sur le site.</p>
        <br/>
        <div className='ul-container md:!ml-4 ml-9'>
          <ul className="!px-0">
            <li>
              <h5 className='!pl-0'>Jeudi 18 juillet</h5>
              <div className="">
                <p>Philippe Brach - Minuit</p>
                <p>Ouverture des portes - 23h : Embarquement continu entre 22h45 et 23h55</p>
              </div>
            </li>
            <li>
              <h5 className='!pl-0'>Vendredi 19 juillet</h5>
              <div className="">
                <p>P’tit Belliveau - Minuit</p>
                <p>Ouverture des portes - 23h : Embarquement continu entre 22h45 et 23h55</p>
              </div>
            </li>
            <li>
              <h5 className='!pl-0'>Samedi 20 juillet</h5>
              <div className="">
                <p>The Planet Smashers</p>
                <p>Ouverture des portes - 23h : Embarquement continu entre 22h45 et 23h55</p>
              </div>
            </li>
          </ul>
        </div>
        <a href='/scenes/quai-bell' className='!no-underline hover:!underline'><h4>Quai Bell</h4></a>
        <p>Les navettes partent entre 10h et 10h15 en direction du Quai Bell. Rendez-vous devant le Arts & Mer Gens, au 51 rue Sainte-Anne.</p>
        <br/>
        <p>Des allers-retours auront lieu entre 10h30 et 11h30.</p>
        <br/>
        <p>Au retour, la navette fera des allers-retours à partir de la fin du spectacle jusqu'à 15h.</p>

        <p><BigButton href="/infos/carte#info_content" text="Voir les stationnements sur la carte" target='_self'/></p>

        <ScenesDistanceFromParvis />

      </div>

    </Wolflayout>
  )
}

export default Transports
